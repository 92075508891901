<template>

    

    <div id="transaction" class="flex flex-col justify-between h-full">
        <div class="transaction-information text-lg font-bold   gap-2 flex justify-between mb-1">
            <div v-if="transaction.account_id">{{ transaction.account_name }}</div>
            <div v-if="transaction.booking_id">{{ transaction.booking_number }}</div>
            <div v-if="transaction.customer_id">{{ transaction.customer_name }}</div>
            <div v-if="transaction.table_id && layout != 'postable'" @click="$refs.modalTableRename.open()">
                {{$t('translations.components.transaction.table')}}:
                <span class="mr-1" v-if="transaction.table_name_temporary && layout != 'postable'">{{
                        transaction.table_name_temporary
                    }} - </span>
                {{ transaction.table_name }}
            </div>
<!-- 
            <div style="text-align: right;" v-if="transaction.table_activated_at">
                <div v-if="showTime">
                    {{ formatDate(transaction.table_activated_at) }}
                </div>
                <div v-else>

                </div>
            </div> -->
            <!--            <div v-if="transaction.table_id && layout != 'postable'" @click="$refs.modalTableRename.open()">{{ vueNumberFormat(transaction.table_amount ? transaction.table_amount : 0) }}</div>-->
            <modal-table-rename ref="modalTableRename" v-if="transaction.table_id && layout != 'postable'" />
        </div>

        <div class="h-full flex flex-col">
            <div class="transaction-body flex-grow overflow-y-auto">

                <!-- transaction -->
                <div v-if="transaction" class="flex flex-col justify-end h-full">
                    <div class="overflow-y-auto transaction-scroller scrollbar-hide">

                        <div v-if="transaction.lines.length > 0 || terminal.transactionscreen == 'empty'"
                             class="transaction-content overflow-y-auto overflow-x-hidden flex flex-col-reverse">

                            <div class="transaction-line flex items-stretch"
                                 :class="{'selected' : line.selected, 'pointer-events-none': line.type === 'cashinout'}"
                                 v-for="(line, index) in transaction.lines.slice().reverse()" :key="index"
                                 @click="lineSelect(line)">


                                <div style="min-width: 12.5%; max-width: 12.5%; width: 12.5%; flex-basis: 12.5%;" class="flex justify-center product-amount">
                                    <div class="leading-5">{{ line.quantity }}</div>
                                </div>

                                <div style="min-width: 15%; max-width: 15%; width: 15%; flex-basis: 15%;" class="h-full flex justify-center order-action minus" :class="{'opacity-50 pointer-events-none': !line.editable, 'opacity-0': line.type === 'cashinout'}">
                                    <a v-if="line.editable" @click="subQuantity(line)"
                                       class="leading-5 text-gray-300 flex justify-center items-center">
                                        <svg class="h-5 w-4" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                        </svg>
                                    </a>
                                </div>

                                <div class="text-left product-name" style="width: 40%; flex-basis: 40%; min-width: 40%; max-width: 40%;"
                                     @click="$refs.modalNotes.openByLine(line)">

                                    <p class="" :class="{'italic ml-3': (line.addon || !line.editable)}">
                                        {{ line.description }}</p>
                                    <div class="italic text-xs mt-2 w-full" v-if="line.notes">
                                        {{ line.notes }}
                                    </div>
                                    <div class="italic text-xs mt-2 w-full" v-if="line.cardnumber">
                                        {{ line.cardnumber }}
                                    </div>
                                    <div class="italic text-xs mt-2 w-full"
                                         v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries"
                                         v-bind:key="grocery_index">
                                        {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                                    </div>
                                    <div class="italic text-xs mt-2 w-full" v-if="line.variant_label">
                                        {{ line.variant_label }}
                                    </div>
                                </div>

                                <div style="min-width: 15%; max-width: 15%; width: 15%; flex-basis: 15%;" class="h-full flex justify-center order-action plus" :class="{'opacity-50 pointer-events-none': !line.editable, 'opacity-0': line.type === 'cashinout'}">
                                    <a v-if="line.editable" @click="addQuantity(line)"
                                       class="text-lg leading-5 flex justify-center items-center">
                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                        </svg>
                                    </a>
                                </div>

                                <div style="min-width: 17.5%; max-width: 17.5%; width: 17.5%; flex-basis: 17.5%"
                                     class="flex items-center justify-end product-price">
                                    <div v-if="line.editable">
                                        <div>{{ vueNumberFormat(line.total) }}</div>
                                        <div class="text-xs text-gray-300" v-if="line.discount_amount > 0">
                                            (-{{ vueNumberFormat(line.discount_amount) }})
                                        </div>
                                    </div>
                                </div>

<!--                                <div class="col-span-1 h-full flex justify-center order-action trash" v-if="line.editable" @click="lineDelete(line)">-->
<!--                                    <a-->
<!--                                       class="inline-flex items-center px-1 py-2 text-xs leading-4 font-medium">-->
<!--                                        <svg class="h-5 w-full" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                            <path fill-rule="evenodd"-->
<!--                                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"-->
<!--                                                  clip-rule="evenodd" />-->
<!--                                        </svg>-->
<!--                                    </a>-->
<!--                                </div>-->
                            </div>
                        </div>

                        <!-- previous transaction -->

                        <div v-if="transactionPrevious && layout !== 'postable'" class="flex-grow">
                            <div
                                v-if="transaction.lines.length == 0 && transactionPrevious.lines.length > 0 && terminal.transactionscreen == 'previous_transaction'"
                                class="h-full flex flex-col flex-col-reverse justify-end opacity-50">
                                <div class="transaction-line grid grid-cols-12 py-1 px-1 flex items-center"
                                     v-for="(line, index) in transactionPrevious.lines.slice().reverse()" :key="index">
                                    <div class="col-span-1 flex justify-end">
                                        <div class="leading-5">{{ line.quantity }}</div>
                                    </div>
                                    <div class="col-span-2 flex justify-center">
                                    </div>
                                    <div class="col-span-5 text-left">
                                        <div class="leading-5 truncate"
                                             :class="{'ml-3 italic': (line.addon || !line.editable)}">
                                            {{ line.description }}
                                        </div>
                                        <div class="italic text-xs"
                                             v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries"
                                             v-bind:key="grocery_index">
                                            {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                                        </div>
                                        <div class="italic text-xs ml-3" v-if="line.variant_label">
                                            {{ line.variant_label }}
                                        </div>
                                    </div>
                                    <div class="col-span-1 flex justify-start">
                                    </div>

                                    <div class="col-span-3 flex items-center justify-end font-bold">
                                        <div v-if="line.editable"  class="flex items-center justify-start">
                                            <div>{{ vueNumberFormat(line.total) }}</div>
                                            <div class="text-xs text-gray-300" v-if="line.discount_amount > 0">
                                                (-{{ vueNumberFormat(line.discount_amount) }})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- transaction -->
            <div v-if="transaction">
                <div
                    v-if="transaction.lines.length > 0 || terminal.transactionscreen == 'empty' || (terminal.transactionscreen == 'previous_transaction' && !transactionPrevious)"
                    id="total" class="px-4 py-2">
                    <div class="flex justify-between font-bold text-lg">
                        <div></div>
                        <div class="flex justify-between w-full">
                            <span class="mr-4">{{$t('translations.components.transaction.total')}} :</span>
                            <span><currency/> {{ vueNumberFormat(total) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- transaction previous -->
            <div v-if="transaction && transactionPrevious && layout !== 'postable'">
                <div
                    v-if="transaction.lines.length == 0 && transactionPrevious.lines.length > 0 && terminal.transactionscreen == 'previous_transaction'"
                    id="total" class="bg-shadow py-2 px-4 rounded-b-sm">
                    <div class="flex justify-between font-bold text-lg">
                        <span class="mr-4">{{$t('translations.components.transaction.paid')}}:</span>
                        <span v-if="transactionPrevious"><currency/>{{ vueNumberFormat(transactionPrevious.total) }}</span>
                    </div>
                    <div v-if="transactionPrevious.balancedue < 0">
                        <div class="flex justify-between text-base">
                            <span class="mr-4">{{$t('translations.components.transaction.receive')}}:</span>
                            <span><currency/>{{ parseFloat((transactionPrevious.balancedue - transactionPrevious.total) * -1).toFixed(2) }}</span>
                        </div>
                        <div class="flex justify-between text-base">
                            <span class="mr-4">{{$t('translations.components.transaction.change')}}:</span>
                            <span><currency/>{{ vueNumberFormat(transactionPrevious.balancedue * -1) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- modals -->
    <modal-notes ref="modalNotes" />

</template>

<script>
import { mapActions } from 'vuex';
// components
import moment from 'moment';

// modals
import ModalTableRename from '../components/ModalTableRename';
import ModalNotes from '../components/ModalNotes';

export default {
    props: ['layout'],
    name: 'Transaction',
    data () {
        return {
            showTime: true,
        }
    },
    components: {
        ModalTableRename,
        ModalNotes,
    },
    methods: {
        ...mapActions({
            // remove: 'transaction/removeItem',
            addQuantity: 'transaction/addQuantity',
            subQuantity: 'transaction/subQuantity',
            lineSelect: 'transaction/lineSelect',
            lineDelete: 'transaction/lineDelete',
        }),

        transactionLineSwipeLeft(event, line) {
            if (!line.editable) {
                return;
            }
        },

         formatDate(time) {
            return moment(time).format('DD-MM HH:mm');
        }
    },
    computed: {
        total () {
            return parseFloat(this.transaction.total) + (this.transaction.table_amount ? parseFloat(this.transaction.table_amount) : 0);
        },
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
        modalActive () {
            return this.$store.getters['terminal/modalActive'];
        },
        transactionPrevious () {
            return this.$store.getters['terminal/transactionPrevious'];
        },
    },
};
</script>
