<template>

    <div v-if="terminal.searchbar_active" class="top-bar-area tile-area">
        <div class="tile-outer">
            <div class="font-bold truncate selected-label" @click="$emit('levelup', group.parent_id)">{{ group.name }}</div>
        </div>

        <div class="relative flex-grow flex items-stretch search pr-2">
            <div class="search-bar flex-grow" @click="toggleActive()">
                <input type="text" v-model="searchquery" :placeholder="$t('translations.components.search.placeholder')" readonly>

<!--                <svg class="w-4 h-4" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"-->
<!--                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
<!--                     viewBox="0 0 487.95 487.95" style="enable-background:new 0 0 487.95 487.95;"-->
<!--                     xml:space="preserve" fill="white">-->
<!--                    <g>-->
<!--                        <g>-->
<!--                            <path d="M481.8,453l-140-140.1c27.6-33.1,44.2-75.4,44.2-121.6C386,85.9,299.5,0.2,193.1,0.2S0,86,0,191.4s86.5,191.1,192.9,191.1-->
<!--                            c45.2,0,86.8-15.5,119.8-41.4l140.5,140.5c8.2,8.2,20.4,8.2,28.6,0C490,473.4,490,461.2,481.8,453z M41,191.4-->
<!--                            c0-82.8,68.2-150.1,151.9-150.1s151.9,67.3,151.9,150.1s-68.2,150.1-151.9,150.1S41,274.1,41,191.4z" />-->
<!--                        </g>-->
<!--                    </g>-->
<!--                    </svg>-->
                <button @click="search()"
                        class="border-0 button button-blur ml-4 mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>
            </div>

            <button @click="searchClear()"
                    class="button button-blur ml-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>

            <div class="vue-keyboard-wrapper shadow" :class="[active ? 'block' : 'hidden']">
                <keyboard
                    v-model="input"
                    @input="changed"
                    :layouts="[
                        '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                        '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                    ]"
                    :maxlength="16"
                ></keyboard>

                <div class="fixed inset-0 transition-opacity" @click="toggleActive()">
                    <div class="absolute inset-0 opacity-0"></div>
                </div>

            </div>
        </div>


        <div v-if="scanner" class="tile-outer">
            <div class="truncate selected-label scan-button">

                <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4615 4.82051C20.4615 4.11246 19.8875 3.53846 19.1795 3.53846L17.1282 3.53846C16.7034 3.53846 16.359 3.19407 16.359 2.76923C16.359 2.3444 16.7034 2 17.1282 2L19.1795 2C20.7372 2 22 3.26279 22 4.82051V6.8718C22 7.29663 21.6556 7.64103 21.2308 7.64103C20.8059 7.64103 20.4615 7.29663 20.4615 6.8718V4.82051Z" fill="#fafafa"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4615 19.1795C20.4615 19.8875 19.8875 20.4615 19.1795 20.4615H17.1282C16.7034 20.4615 16.359 20.8059 16.359 21.2308C16.359 21.6556 16.7034 22 17.1282 22H19.1795C20.7372 22 22 20.7372 22 19.1795V17.1282C22 16.7034 21.6556 16.359 21.2308 16.359C20.8059 16.359 20.4615 16.7034 20.4615 17.1282V19.1795Z" fill="#fafafa"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.53846 4.82051C3.53846 4.11246 4.11246 3.53846 4.82051 3.53846L6.8718 3.53846C7.29663 3.53846 7.64103 3.19407 7.64103 2.76923C7.64103 2.3444 7.29663 2 6.8718 2L4.82051 2C3.26279 2 2 3.26279 2 4.82051L2 6.8718C2 7.29663 2.3444 7.64103 2.76923 7.64103C3.19407 7.64103 3.53846 7.29663 3.53846 6.8718L3.53846 4.82051Z" fill="#fafafa"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.53846 19.1795C3.53846 19.8875 4.11246 20.4615 4.82051 20.4615H6.8718C7.29663 20.4615 7.64103 20.8059 7.64103 21.2308C7.64103 21.6556 7.29663 22 6.8718 22H4.82051C3.26279 22 2 20.7372 2 19.1795L2 17.1282C2 16.7034 2.3444 16.359 2.76923 16.359C3.19407 16.359 3.53846 16.7034 3.53846 17.1282L3.53846 19.1795Z" fill="#fafafa"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.77996 5.36753C8.98255 5.23497 10.686 5.07692 12 5.07692C13.314 5.07692 15.0175 5.23497 16.22 5.36753C17.493 5.50784 18.4922 6.50702 18.6325 7.77996C18.765 8.98255 18.9231 10.686 18.9231 12C18.9231 13.314 18.765 15.0175 18.6325 16.22C18.4922 17.493 17.493 18.4922 16.22 18.6325C15.0175 18.765 13.314 18.9231 12 18.9231C10.686 18.9231 8.98255 18.765 7.77996 18.6325C6.50702 18.4922 5.50784 17.493 5.36753 16.22C5.23497 15.0175 5.07692 13.314 5.07692 12C5.07692 10.686 5.23497 8.98255 5.36752 7.77996C5.50784 6.50702 6.50702 5.50784 7.77996 5.36753ZM12 6.61539C10.7769 6.61539 9.14545 6.76479 7.94852 6.89673C7.3918 6.95809 6.95809 7.3918 6.89672 7.94852C6.76479 9.14545 6.61539 10.7769 6.61539 12C6.61539 13.2231 6.76479 14.8546 6.89672 16.0515C6.95809 16.6082 7.3918 17.0419 7.94852 17.1033C9.14545 17.2352 10.7769 17.3846 12 17.3846C13.2231 17.3846 14.8546 17.2352 16.0515 17.1033C16.6082 17.0419 17.0419 16.6082 17.1033 16.0515C17.2352 14.8546 17.3846 13.2231 17.3846 12C17.3846 10.7769 17.2352 9.14545 17.1033 7.94852C17.0419 7.3918 16.6082 6.95809 16.0515 6.89673C14.8546 6.76479 13.2231 6.61539 12 6.61539Z" fill="#fafafa"/>
                </svg>

                <span>Scan</span>
            </div>
        </div>
    </div>
</template>

<script>


// mixins

import KeyboardHelper from '../mixins/keyboard.helper.js';

export default {
    mixins: [KeyboardHelper],
    props: ['group'],
    data () {
        return {
            searchquery: '',
            active: false,
            scanner: false,
        };
    },

    methods: {

        toggleActive () {
            this.active = !this.active;
        },

        search () {
            this.active = false;
            this.$emit('search', this.searchquery);
        },

        searchClear () {
            this.active = false;
            this.searchquery = '';
            this.$emit('search', false);
        },

        changed (value) {
            if (value.length > 0)
                this.searchquery += value;
            else {
                this.searchquery = this.searchquery.slice(0, -1);
            }
            this.$emit('search', this.searchquery);
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    },
};
</script>
