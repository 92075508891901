<template>
    <div v-if="active" class="not-italic fixed z-20 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div id="modal" class="mobile-y-compensator inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                <modal-close @close="close()"/>

                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                    <div class="modal-content">
                        <div class="flex justify-between items-center mb-5">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    {{$t('translations.components.modal-table-rename.title')}}
                                    <!-- <span class="mr-4" v-if="transaction.table_name_temporary">{{ transaction.table_name_temporary }}</span> -->
                                    {{ transaction.table_name }}
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div class="mb-5 default-input">
                                <label for="name" class="block" :class="{'text-red-500': errors.name}">{{$t('translations.components.modal-table-rename.name')}}</label>
                                <div class="">
                                    <Field type="text" name="name" class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :class="{'error': errors.name}" />
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="button button-filled button-large button-wide">
                            {{$t('translations.components.modal-table-rename.save')}}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import { Field, Form } from 'vee-validate';
import * as yup from "yup";

import ModalClose from "./ModalClose";

export default {
    props: ['table_id'],
    emits: ['saved'],
    components: {
        ModalClose,
        Field,
        Form,
    },
    data () {
        const schema = yup.object().shape({
            name: yup.string().required().max(255),
        });

        return {
            active: false,
            schema,
        }
    },

    methods: {
        open() {
            this.active = true
        },

        close() {
            this.active = false
        },

        async onSubmit(data) {
            axios.post('/tables/' + this.transaction.table_id + '/rename', data).then(response => {
                this.$store.dispatch('transaction/setTable', response.data.data)
                this.$emit('saved')
                this.close()
            }).catch(error => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                console.error(error)
            })
        },
    },
	computed: {
		transaction() {
			return this.$store.getters['transaction/transaction'];
        },
    }
}
</script>

