<template>
    <div v-if="active" class="fixed z-40 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div id="modal"
                 class="mobile-y-compensator w-full inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">
                    <div class="flex justify-between items-center mb-2">
                        <div class="sm:mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.modal-notes.title')}}
                            </h3>

                            <p v-if="line" class="leading-6 text-base mt-2 font-bold text-left" id="modal-headline">
                                {{ line.description }}
                            </p>
                        </div>
                        <modal-close @close="close()" />
                    </div>
                    <div>
                        <textarea autofocus v-model="notes" name="notes"
                                  class="text-sm py-1 px-2 h-32 block w-full shadow-sm  rounded-sm focus:outline-none focus:ring-transparent" />
                    </div>
                    <keyboard
                        v-if="!isMobile"
                        v-model="input"
                        @custom="custom"
                        @input="changed"
                        :layouts="[
                        '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                        '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                    ]"
                        :maxlength="16"
                    ></keyboard>
                </div>
                <div class="modal-footer p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span class="flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                        <a @click="save()"
                           class="button button-filled button-large">
                           {{$t('translations.components.modal-notes.save')}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// mixins
import KeyboardHelper from '../mixins/keyboard.helper.js';
import ModalClose from "./ModalClose";

export default {
    mixins: [KeyboardHelper],
    props: ['item_id', 'initial_notes'],
    components: {
        ModalClose,
    },
    data () {
        return {
            field: 'notes',
            type: null,
            active: false,
            line: null,
            notes: '',
        };
    },

    methods: {
        openByLine (line) {
            this.type = 'line';
            this.active = true;
            this.line = line;
            this.notes = line.notes ? line.notes : '';
        },

        openByItems (items) {
            this.type = 'items';
            this.active = true;
            this.notes = this.initial_notes ? this.initial_notes : this.getNotes(items);
        },

        close () {
            this.active = false;
        },

        save () {
            if (this.type == 'line') {
                this.line.notes = this.notes;
            } else {
                this.$emit('saved', this.notes);
            }

            this.close();
        },

        getNotes (items) {
            if (!items) {
                return;
            }

            let notes;
            let self = this;

            items.forEach(line => {
                if (self.item_id == line.item_id) {
                    notes = line.notes;
                }
            });
            return notes;
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
        isMobile () {
            return this.$store.getters['terminal/isMobile'];
        },
    },
};
</script>
