<template>
    <div>

        <div v-if="view == 'button' && initialitem.attributes.available_in_inventory == true" @click="addItem()"
             class="tile-outer">
            <div class="tile relative item border"
                 :class="{'unavailable': !initialitem.attributes.available_in_inventory, 'active': numberInTransaction != 0, 'tile-small': tilesize == 'small', 'tile-medium': tilesize == 'medium', 'tile-large': tilesize == 'large'}"

                 :style="tileBorderStyle(numberInTransaction, tilesize, initialitem)">

                <div class="item-counter" v-show="numberInTransaction != 0">{{ numberInTransaction }}</div>
                <div class="item-price">{{ vueNumberFormat(initialitem.attributes.pos_price) }}</div>
                <h2 class="item-title">{{ initialitem.attributes.pos_description }}</h2>
            </div>
        </div>
        <div v-if="view == 'button' && initialitem.attributes.available_in_inventory == false" class="tile-outer ">
            <div class="tile relative item unavailable border"
                 :style="'border-color: ' + initialitem.attributes.pos_color"
            >

<!--                :style="[child.color ? {'border-color': child.color} : {'border-color': selected_group.color}]"-->
                <div class="italic item-price">{{$t('translations.components.item.not-available')}}</div>
                <h2 class="item-title">{{ initialitem.attributes.pos_description }}</h2>
            </div>
        </div>

        <div v-if="view == 'mobile' && initialitem.attributes.available_in_inventory == true" @click="addItem()"
             class="item cursor-pointer p-1 border border-gray-100 overflow-hidden rounded-sm tileBorder"
             :class="{'unavailable': !initialitem.attributes.available_in_inventory}"
             :style="tileBorderStyle(numberInTransaction, 'mobile', initialitem)"
        >
            <div class="flex justify-between">
                <div class="text-xs">
                    <span v-show="numberInTransaction != 0">{{ numberInTransaction }}</span>
                </div>
                <div class="text-xs">{{ vueNumberFormat(initialitem.attributes.pos_price) }}</div>
            </div>
            <h2 class="text-xs text-white truncate font-bold">{{ initialitem.attributes.pos_description }}</h2>
        </div>

        <div v-if="view == 'mobile' && initialitem.attributes.available_in_inventory == false"
             class="item cursor-pointer p-1 border border-gray-100 rounded-sm"
             :style="'border-width: 1px 1px 15px 1px;'">
            <div class="text-xs italic">{{$t('translations.components.item.out-of-stock')}}</div>
            <h2 class="text-xs text-white truncate font-bold">{{ initialitem.attributes.pos_description }}</h2>
        </div>

        <!-- todo: move to own component? -->
        <!-- modal -->
        <div v-if="show_modal" class="fixed z-30 inset-0 overflow-y-auto">

            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" @click="close()">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div id="modal"
                     class="w-full add-ons modal-extra-large inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg"
                     :class="step + ' ' +  'tile-' + tilesize"
                     role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                    <modal-close @close="close()"/>

                    <div class="modal-content">
                        <div class="sm:flex sm:items-start">
                            <div class="modal-title px-1 mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    {{ item.attributes.webshop_description }}
                                </h3>
                            </div>
                        </div>

                        <div class="overflow-y-auto overflow-x-hidden flex-grow content-wrapper">
                            <!-- Details -->
                            <div v-if="step == 'details'">
                                <div class="mt-2">
                                    <div v-if="item.attributes.thumbnail_url" class="flex justify-center">
                                        <img class="max-h-20" :src="item.attributes.thumbnail_url">
                                    </div>
                                    <p>{{ item.attributes.webshop_description_long }}</p>
                                </div>
                            </div>

                            <!-- OpenPrice -->
                            <div v-if="step == 'openprice'">
                                <h2 class="my-2 text-lg font-bold">
                                    {{$t('translations.components.item.give-price')}}
                                </h2>
                                <div class="h-5">
                                    <div class="text-xs text-red-600" v-if="errors.openprice">{{$t('translations.components.item.invalid-price')}}</div>
                                </div>
                                <numpad :default="item.attributes.pos_price" :formatted="true"
                                        @inputchanged="setOpenprice($event);errors.openprice = false" />
                            </div>

                            <!-- Variants -->
                            <div v-if="step == 'variants'">
                                <div class="justify-center mt-4">
                                    <!-- <span class="relative z-0 inline-flex shadow-sm rounded-sm "> -->
                                    <div class="w-full mb-2"
                                         v-for="(item_variant, variant_index) in item.attributes.variants"
                                         :key="variant_index">
                                        <!-- avaialble -->
                                        <a v-if="item_variant.attributes.available_in_inventory"
                                           @click="variantSelect(item_variant)"
                                           class="cursor-pointer w-full relative flex justify-between items-center px-4 py-2 -ml-px text-sm leading-5 button-blur font-medium focus:z-10 focus:outline-none"
                                           :class="item_variant.id == (variant ? variant.id : null) ? 'btn-primary' : 'btn-secondary'">
                                            <div>{{ item_variant.attributes.label }}</div>
                                            <div class="font-bold"><currency/>{{ item_variant.attributes.price }}</div>
                                        </a>

                                        <!-- soldout -->
                                        <a v-else
                                           class="opacity-75 line-through button-blur cursor-pointer w-full relative flex justify-between items-center px-4 py-2 -ml-px text-sm leading-5 font-medium focus:z-10 focus:outline-none">
                                            <div>{{ item_variant.attributes.label }}</div>
                                        </a>
                                    </div>
                                    <!-- </span> -->
                                </div>
                                <div class="text-xs text-red-600" v-if="errors.variants && !variant">{{$t('translations.components.item.required')}}</div>
                            </div>

                            <!-- Addons -->
                            <template v-if="step == 'addons'">
                                <div class="mb-5" v-for="(addon, addon_index) in addons" :key="addon_index"
                                    :class="addon.type + '-view'"
                                >
                                    <div class="font-bold flex justify-between mb-3 addon-title">
                                        <div>
                                            {{ addon.description }}
                                            <span v-if="addon.quantity_min > 0">*</span>
                                        </div>

                                        <div v-if="maxesString && addon.type == 'tiles'" class="text-red-600 text-xs">
                                            {{ maxesString }}
                                        </div>

                                        <!-- <div>{{ option.description }} ({{ option.price }})</div> -->
                                        <div class="text-xs text-red-600" v-if="addon.error">
                                            {{$t('translations.components.item.select')}}
                                            <span
                                                v-if="addon.quantity_min && addon.quantity_max">{{$t('translations.components.item.minimal')}} {{ addon.quantity_min }} en {{$t('translations.components.item.maximal')}} {{
                                                    addon.quantity_max
                                                }}</span>
                                            <span
                                                v-else-if="addon.quantity_min">{{$t('translations.components.item.minimal')}} {{ addon.quantity_min }}</span>
                                            <span v-else-if="addon.quantity_max">{{$t('translations.components.item.maximal')}} {{
                                                    addon.quantity_max
                                                }}</span>
                                        </div>
                                        <!-- <span class="text-xs text-red-600" v-if="hasError('addons', addon.description)">verplicht</span> -->
                                    </div>
                                    <div class="addon-flexer">
                                        <div class="addons" v-for="(option, option_index) in addon.options"
                                            :key="option_index"
                                        >
                                            <!-- Quantity -->
                                            <div v-if="addon.type == 'buttons'" class="flex justify-start items-center">
                                                <div class="flex rounded-sm  items-center justify-end mr-5">
                                                    <div class="">
                                                        <a @click="changeAddonQuantity(option, -1)"
                                                        class="block rounded-sm p-1 order-action minus">
                                                            <svg class="h-6 w-5" fill="currentColor" viewBox="0 0 20 20">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                                            </svg>
                                                        </a>
                                                    </div>

                                                    <div class="px-5 font-bold">
                                                        {{ parseInt(option.quantity) }}
                                                    </div>

                                                    <div>
                                                        <a @click="changeAddonQuantity(option, 1)"
                                                        class="block rounded-sm p-1 order-action plus">
                                                            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="flex justify-between items-center w-full">
                                                    <div>
                                                        <div>{{ option.description }} ({{ option.price }})</div>
                                                        <div class="text-xs text-red-600" v-if="option.error">
                                                            <span v-if="option.quantity_min && option.quantity_max">{{$t('translations.components.item.minimal')}} {{ option.quantity_min }} en {{$t('translations.components.item.maximal')}} {{
                                                                    option.quantity_max
                                                                }}</span>
                                                            <span
                                                                v-else-if="option.quantity_min">{{$t('translations.components.item.minimal')}} {{ option.quantity_min }}</span>
                                                            <span v-else-if="option.quantity_max">{{$t('translations.components.item.maximal')}} {{
                                                                    option.quantity_max
                                                                }}</span>
                                                        </div>
                                                    </div>
                                                    <div><currency/>{{ vueNumberFormat(option.quantity * option.price) }}</div>
                                                </div>
                                            </div>

                                            <!-- Radio -->
                                            <div v-if="addon.type == 'radio'" class="flex justify-between items-center">
                                                <div class="flex items-center justify-start gap-5">
                                                    <input @change="addonRadioSelect(addon, option)" type="radio"
                                                        :id="option.id" :name="addon.id" :value="option.id">
                                                    <label :for="option.id">{{ option.description }} ({{
                                                            option.price
                                                        }})</label>
                                                </div>
                                                <div v-if="option.quantity > 0"><currency/>
                                                    {{ vueNumberFormat(option.quantity * option.price) }}
                                                </div>
                                            </div>

                                            <!--Tiles-->

                                        <!-- {{option}} -->

                                            <!-- {{addon}} -->

                                            <a v-if="addon.type == 'tiles'" 
                                                class="tiles">
                                                <div class="tile relative item border tile-medium"
                                                :style="{'border-color': option.pos_color || this.parentcolor}" 
                                                :class="{'active': option.quantity > 0 }"
                                                >

                                                    <span class="inner"                                       
                                                        @click="processMinMaxAddon(option, 1, [option.quantity, option.quantity_max, addon.quantity_max]);"
                                                    ></span>

                                                    <span class="item-price">{{vueNumberFormat(option.price) }}</span>


                                                    <span class="name">{{option.description }}</span>
                                                    <span class="quantity item-counter" v-if="option.quantity">
                                                        {{option.quantity}}
                                                    </span>
                                                    <span class="minus" v-if="option.quantity"
                                                        @click="processMinMaxAddon(option, -1)"
                                                    >
                                                        -
                                                    </span>
                                                </div>

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <!-- Kitchen Groceries -->
                            <div id="kitchengroceries" v-if="step == 'kitchen_groceries'">
                                <!-- kitchen groceries -->
                                <div class="mb-3"
                                     v-for="(kitchen_grocery, kitchen_grocery_index) in item.attributes.kitchen_groceries"
                                     :key="kitchen_grocery_index">
                                    <fieldset class="mb-3">
                                        <legend class="w-full font-bold flex justify-between">
                                            <span class="capitalize">{{ kitchen_grocery.description }} *</span>
                                            <span class="text-xs text-red-600"
                                                  v-if="hasError('kitchen_groceries', kitchen_grocery.description)">{{$t('translations.components.item.required')}}</span>
                                        </legend>
                                        <ul class="space-y-1" role="radiogroup" aria-labelledby="radiogroup-label">
                                            <li v-for="(option, option_index) in kitchen_grocery.options.split(';')"
                                                :key="option_index" tabindex="0" role="radio"
                                                class="group relative rounded-sm shadow-sm button-blur mt-2 cursor-pointer focus:outline-none">
                                                <div @click="setKitchenGrocery(kitchen_grocery.description, option)"
                                                     class="px-4 py-2 mt-2 sm:flex button-blur sm:justify-between">
                                                    <div class="flex items-center">
                                                        <div class="text-sm">
                                                            <p class="capitalize font-medium">
                                                                {{ option }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="isKitchenGrocerySelected(kitchen_grocery.description, option)"
                                                    class="border-highlight absolute inset-0 rounded-sm border-2 pointer-events-none"
                                                    aria-hidden="true"></div>
                                            </li>
                                        </ul>
                                    </fieldset>
                                </div>
                            </div>

                            <!-- Card -->
                            <!-- <div v-if="step == 'card'">
                                <div class="mt-5 flex rounded-sm  h-10">
                                    <div class="relative flex-grow focus-within:z-10">
                                        <input type="text" v-model="cardnumber" placeholder="Scan kaart"
                                               class="h-10 py-2 px-2 block w-full shadow-sm border rounded-l-sm focus:outline-none focus:ring-transparent" />
                                    </div>
                                    <a @click="cardStatus()"
                                       class="button-default -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-sm text-gray-700 bg-shadow hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                                        <span>Bevestigen</span>
                                    </a>
                                </div>

                                <div v-if="item.card" class="mt-10 text-center p-5 bg-gray-600 border rounded-sm">
                                    <div v-if="item.card.status == 0 || item.card.status == 114">
                                        <div
                                            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                            <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg"
                                                 fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                 aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <div class="mt-5 text-green-500 font-bold text-2xl">Geldig</div>
                                        <div class="pt-10 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                                            <a @click="nextStep('card')"
                                               class="button-primary inline-flex justify-center w-full rounded-sm  border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                                Toevoegen aan transactie
                                            </a>
                                        </div>
                                        <div class="pt-10 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                                            <a @click="cardCancel()"
                                               class="button-primary inline-flex justify-center w-full rounded-sm  border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                                Cancel
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else-if="item.card.status == 119">
                                        <div
                                            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-600"
                                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                        <div class="mt-5 text-red-500 font-bold text-2xl">Niet geactiveerd</div>
                                        <div class="pt-10 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                                            <a @click="cardActivate()"
                                               class="button-primary inline-flex justify-center w-full rounded-sm  border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                                Activeren
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div
                                            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-600"
                                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                        <div class="mt-5 text-red-500 font-bold text-2xl">Ongeldig</div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="modal-footer px-4 py-4 sm:px-6 sm:flex sm:justify-between items-center">

                        <!-- Quantity -->
                        <div>
                            <div v-if="item.attributes.quantity_fixed == false"
                                 class="flex rounded-sm  sm:mb-0 mb-3 items-center justify-center sm:justify-end">
                                <div class="">
                                    <a @click="subQuantity()" class="block rounded-sm border border-gray-200 p-1 order-action minus">
                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                        </svg>
                                    </a>
                                </div>
                                <div class="text-main px-5">
                                    {{ quantity }}
                                </div>
                                <div>
                                    <a @click="addQuantity()" class="block rounded-sm border border-gray-200 p-1 order-action plus">
                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="sm:flex sm:flex-row-reverse">
                            <span class="flex w-full rounded-sm  shadow-sm sm:ml-3 sm:w-auto">
                                <a v-if="step == 'details'" @click="nextStep('details')"
                                   class="button button-filled button-large">
                                   {{$t('translations.components.item.confirm')}}
                                </a>
                                <a v-if="step == 'openprice'" @click="confirmOpenPrice()"
                                   class="button button-filled button-large">
                                    {{$t('translations.components.item.confirm')}}
                                </a>
                                <!-- <a v-if="step == 'variants'" @click="confirmVariant()" class="button-primary inline-flex justify-center w-full rounded-sm  border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                    Bevestigen
                                </a> -->
                                <a v-if="step == 'addons'" @click="confirmAddons()"
                                   class="button button-filled button-large">
                                    {{$t('translations.components.item.confirm')}}
                                </a>
                                <a v-if="step == 'kitchen_groceries'" @click="confirmKitchenGroceries()"
                                   class="button button-filled button-large">
                                    {{$t('translations.components.item.confirm')}}
                                </a>
                                <!-- <a @click="addItemToCart()" class="button-primary inline-flex justify-center w-full rounded-sm  border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                   Toevoegen
                               </a> -->
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-wallet-add-credit ref="walletaddcredit" @opened="close()" :allow-scanner="true" />
    </div>
</template>

<style scoped>
body {
    position: fixed;
}
</style>

<script>
import ItemHelper from '../mixins/item.helper.js';

// components
import Numpad from '../components/Numpad';

import ModalClose from "./ModalClose";
import ModalWalletAddCredit from './ModalWalletAddCredit.vue';

export default {
    name: 'Item',
    props: ['view', 'tilesize', 'initialitem', 'initialquantity', 'parentcolor'],
    mixins: [ItemHelper],
    components: {
        ModalClose,
        ModalWalletAddCredit,
        Numpad,
    },
    data() {
        return {
            totalAddon: 0,
            maxesString: '',
        }
    },
    methods: {
        processMinMaxAddon (option, quantity, maxes = false) {
            // const quantityItem = maxes[0];
            // const maxOption = maxes[1]
            // const totalMax = maxes[2]; todo

            this.maxesString = '';

            if (quantity == -1 || !maxes) {
                this.changeAddonQuantity(option, quantity)
                return;
            }

            const existingAddons = document.querySelectorAll('.quantity.item-counter');
            let existingAddonsCount = 0;
            existingAddons.forEach(function(elem) {
                if (!isNaN(parseFloat(elem.innerHTML)) && isFinite(elem.innerHTML)) {
                    existingAddonsCount += parseFloat(elem.innerHTML);
                }
            });

            if (existingAddonsCount >= maxes[2]) {
                this.maxesString = 'max ' + maxes[2] + ' total';
                return;
            }
            
            //cant extend totals
            if (maxes[0] >= maxes[1] && maxes[1] !== 0) {
                this.maxesString = 'max ' + maxes[1] + ' ' + option.description;
                return;
            }

             if (maxes[0] >= maxes[2]) {
                this.maxesString = 'max ' + maxes[2] + ' total';
                return;
            }

            this.changeAddonQuantity(option, quantity)
        },

        tileBorderStyle (amount, itemsize, item) {

            let color = item.attributes.pos_color;

            if (!color) {
                color = this.parentcolor;
            }

            if (itemsize === 'mobile') {
                return 'border-color: ' + color + ';';
            }

            if (itemsize === 'small') {
                return 'border-color: ' + color + ';';
            }

            if (amount != 0) {
                return 'border-color: ' + color + ';';
            }

            return 'border-color: transparent; border-bottom-color: ' + color + ';';
        },
    },
    computed: {
        numberInTransaction () {
            let self = this;
            let quantity = 0;
            this.transaction.lines.forEach(line => {
                if (self.initialitem.id == line.item_id) {
                    quantity += line.quantity;
                }
                return line.item_id == this.initialitem.id;
            });
            return quantity;
        },
    },
};
</script>
