<template>
    <navigation-mobile-pos title="Pin only" :minimal="true"/>

    <loading-indicator v-if="!initialized"/>

    <div class="pin-only-wrapper">
        <div class="pin-only-items-wrapper">
            <div class="pin-only-items">
                <a v-for="(item, key) in filteredItems" :key="key" class="button button-blur"
                    @click="selectItem(key, item)"
                    :class="{'active' : key === selectedItemKey}"
                    >
                        {{ item.attributes.description }}
                </a>
            </div>
        </div>

        <div class="payment-wrapper">

            <div class="order">
                {{ (price/100).toFixed(2) }}
            </div>

            <div class="numpad-wrapper">
                <numpad :pinonly="true" :formatted="true" @inputchanged="price = $event" class="md:w-1/3 mx-auto" />
            </div>

            <div class="paymenttypes-wrapper">
                <template v-for="(paymenttype, index) in paymenttypes" :key="index" >
                    <a v-if="paymenttype.attributes.preferred_payment" @click="submit(paymenttype.id)" class="button button-large button-filled">
                        {{ paymenttype.attributes.name }}
                    </a>
                </template>
                
                <a @click="submit()" class="button button-large">
                    {{$t('translations.views.mobilepos.pages.pinonly.otherpaymentmethods')}}
                </a>
            </div>
        </div>
    </div>
</template>

<style>
.border-invisible{
    border: 3px solid transparent;
}
.active{
    border: 3px solid #fff;
}
</style>

<script>
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter';

// mixins
import ItemHelper from '../../../mixins/item.helper.js';
import PosHelper from '../../../mixins/pos.helper.js';

// components
import NavigationMobilePos from '../../../components/NavigationMobilePos'
import LoadingIndicator from '../../../components/LoadingIndicator'
import Numpad from '../../../components/Numpad';

const cache = setupCache({
    maxAge: 15 * 60 * 1000,
});
const axiosCache = axios.create({
    adapter: cache.adapter,
});

export default {
    name: 'mobilepos.pinonly',
    mixins: [ItemHelper, PosHelper],
    components: {
        NavigationMobilePos,
        LoadingIndicator,
        Numpad,
    },
    data() {
        return {
            initialized: false,
            paymenttypes: null,
            price: null,
            items: [],
            selectedItemKey: null,
            activeItem: null,
        }
    },
    mounted() {
        this.initialized = true;
        this.paymenttypesGet();
        this.selectItem(0);
    },
    methods: {
        selectItem(key, item = false){
            if (item) {
                this.activeItem = item;
            }

            // this.selectedItemLabel = item.label
            this.selectedItemKey = key;
        },
        async submit(paymenttype_id) {
            if(this.selectedItemKey === null){
                return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: this.$i18n.messages[this.$i18n.locale].translations.views.mobilepos.pages.pinonly.itemnotification.title,
                        text: this.$i18n.messages[this.$i18n.locale].translations.views.mobilepos.pages.pinonly.itemnotification.text,
                        }, 2000);
            }
            this.$store.dispatch('transaction/clear');
            let item = this.filteredItems[this.selectedItemKey];
            if (!this.price) {
                return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: this.$i18n.messages[this.$i18n.locale].translations.views.mobilepos.pages.pinonly.pricenotification.title,
                        text: this.$i18n.messages[this.$i18n.locale].translations.views.mobilepos.pages.pinonly.pricenotification.text,
                        }, 2000);
            }
            item.attributes.pos_price = parseFloat(this.price / 100);
            this.addItemByScannedBarcode(item);
            this.gotoTransaction(paymenttype_id)
        },
        async paymenttypesGet() {
            axiosCache({
                url: '/paymenttypes',
                method: 'get',
            })
                .then(response => {
                    this.paymenttypes = response.data.data;
                }).catch(() => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                });
        },
    },
    computed: {
        terminal() {
            return this.$store.getters['terminal/terminal'];
        },
        filteredItems(){
            this.groups.forEach(el => {
                el.items.forEach(i => {
                    this.items.push(i);
                })
            });
            return this.items;
        }
    }
}
</script>

