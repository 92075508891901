import {add as addPrintjob} from '../lib/printerqueue';

export default class DrawerOpen {

    print() {
        // eslint-disable-next-line no-undef
        var builder = new StarWebPrintBuilder();
        // Create Request
        var request = '';
        request += builder.createPeripheralElement({ channel: 1, on: 200, off: 200 })

        // add to queue
        addPrintjob(request);

        return;
    }
}
