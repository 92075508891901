import axios from 'axios'
import store from '../store';
import { add as addPrintjob } from '../lib/printerqueue';

export default class PrintReceipt {

    print(transaction) {
        if (store.getters['terminal/terminal'].printer_mode == 'cloudprint') {
            axios.post('/transactions/' + transaction.id + '/cloudprint')
                .then(() => {})
                .catch(() => {
                    console.log('error')
                })
            return
        }

        // eslint-disable-next-line no-undef
        var builder = new StarWebPrintBuilder();
    // Create Request
        var request = '';
        // var paperwidth = 'inch3DotImpact';

        request += builder.createLogoElement({number:1, width:'single', height:'single'});

        request += builder.createTextElement({ characterspace: 2 });
        // request += builder.createTextElement({ characterspace: 2 });
        request += builder.createAlignmentElement({ position: 'center' });

        request += builder.createTextElement({ emphasis: true });

        let self = this
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: transaction.relationships.location.attributes.name });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: (transaction.relationships.location.attributes.streetname ? transaction.relationships.location.attributes.streetname : '') + ' ' + (transaction.relationships.location.attributes.housenumber ? transaction.relationships.location.attributes.housenumber : '') });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: (transaction.relationships.location.attributes.zipcode ? transaction.relationships.location.attributes.zipcode : '') + ' ' + (transaction.relationships.location.attributes.city ? transaction.relationships.location.attributes.city : '') });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: transaction.relationships.location.attributes.email });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: transaction.relationships.location.attributes.phone_number });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ emphasis: false });

        if (transaction.relationships.table) {
            request += builder.createTextElement({ emphasis: false });
            request += builder.createTextElement({ data: '\n' });
            request += builder.createTextElement({ data: '\n' });
            request += builder.createTextElement({ data: transaction.relationships.table.attributes.name });
            request += builder.createTextElement({ data: '\n' });
        }

        request += builder.createAlignmentElement({ position: 'left' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });



        if (transaction.attributes.type == 'cash_inout' && transaction.relationships.cashfunction) {
            request += builder.createTextElement({ emphasis: true });
            request += builder.createTextElement({ data: 'KAS Functie: ' + transaction.relationships.cashfunction.attributes.description });
            request += builder.createTextElement({ data: '\n' });
            request += builder.createTextElement({ emphasis: false });
        }

        // Headings
        if (transaction.attributes.type != 'cash_inout') {
            if (store.getters['printer/paperwidth'] == '58mm') {
                request += builder.createTextElement({
                    emphasis: true,
                    data: self.format_string('AANT', 5) + '  ' + self.format_string('OMSCHR', 11) + '  ' + self.format_string('TOTAAL', 7, 'right')
                });
            } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
                request += builder.createTextElement({
                    emphasis: true,
                    data: self.format_string('AANT', 5) + '  ' + self.format_string('OMSCHRIJVING', 25) + '  ' + self.format_string('TOTAAL', 7, 'right')
                });
            }
        }

        request += builder.createTextElement({
            data: '\n',
            emphasis: false
        });

        // Lines
        let lines = transaction.relationships.lines

        if (store.getters['printer/paperwidth'] == '58mm') {
            lines.forEach(function (line) {
                if (line.attributes.hidden_receipt) {
                    return
                }
                request += builder.createTextElement({
                    // data: self.format_string(parseFloat(line.attributes.quantity).toFixed(2), 5, 'left') + '  ' + (line.attributes.addon ? ' ' : '') + self.format_string(line.attributes.description, (line.attributes.addon ? 10 : 11)) + '  ' + self.format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'
                    data: self.format_string(parseFloat(line.attributes.quantity), 5, 'left') + '  ' + (line.attributes.addon ? ' ' : '') + self.format_string(line.attributes.description, (line.attributes.addon ? 10 : 11)) + '  ' + self.format_string(parseFloat(line.attributes.total).toFixed(2), 7, 'right') + '\n'
                    // data: (line.attributes.addon ? ' ' : '') + self.format_string(line.attributes.description, (line.attributes.addon ? 10 : 11)) + '  ' + self.format_string(parseFloat(line.attributes.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'
                });

                // variant
                if (line.attributes.variant) {
                    request += builder.createTextElement({
                        data: ' ' + self.format_string(line.attributes.variant, 20) + '\n'
                    });
                }
                // discount
                if (line.attributes.discount_amount > 0) {
                    request += builder.createTextElement({
                        data: ' Korting ' + parseFloat(line.attributes.discount_amount).toFixed(2) + '\n'
                    });
                }

                // kitchengroceries
                let kitchen_groceries = Object.entries(line.attributes.kitchen_groceries)

                kitchen_groceries.forEach(function (value) {
                    request += builder.createTextElement({
                        data: ' ' + self.format_string(value[0], 7) + ': ' + value[1] + '\n'
                    });
                })
            })

        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            lines.forEach(function (line) {
                if (line.attributes.hidden_receipt) {
                    return
                }
                request += builder.createTextElement({
                    // data: (line.attributes.addon ? ' ' : '') + self.format_string(line.attributes.description, (line.attributes.addon ? 16 : 17)) + '  ' + self.format_string(parseFloat(line.attributes.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'
                    data: self.format_string(parseFloat(line.attributes.quantity), 5, 'left') + '  ' + (line.attributes.addon ? ' ' : '') + self.format_string(line.attributes.description, (line.attributes.addon ? 25 : 26)) + '  ' + self.format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'

                });

                // variant
                if (line.attributes.variant) {
                    request += builder.createTextElement({
                        data: ' ' + self.format_string(line.attributes.variant, 25) + '\n'
                    });
                }

                // discount
                if (line.attributes.discount_amount > 0) {
                    request += builder.createTextElement({
                        data: ' Korting ' + parseFloat(line.attributes.discount_amount).toFixed(2) + '\n'
                    });
                }

                // kitchengroceries
                let kitchen_groceries = Object.entries(line.attributes.kitchen_groceries)

                kitchen_groceries.forEach(function (value) {
                    request += builder.createTextElement({
                        data: ' ' + self.format_string(value[0], 7) + ': ' + value[1] + '\n'
                    });
                })
            })
        }

        request += builder.createRuledLineElement({ thickness: 'double_thin' });

        //  totals
        if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('TOTAAL', 16) + '  ' + self.format_string(parseFloat(transaction.attributes.total).toFixed(2), 9, 'right') + '\n'
            });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('TOTAAL', 30) + '  ' + self.format_string(parseFloat(transaction.attributes.total).toFixed(2), 9, 'right') + '\n'
            });
        }


        request += builder.createTextElement({emphasis: false});

        // Balancedue
        if (transaction.attributes.balancedue > 0) {
            if (store.getters['printer/paperwidth'] == '58mm') {
                request += builder.createTextElement({
                    data: self.format_string('Nog te betalen', 18) + '  ' + self.format_string(parseFloat(transaction.attributes.balancedue).toFixed(2), 7, 'right') + '\n'
                });
            } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
                request += builder.createTextElement({
                    data: self.format_string('Nog te betalen', 25) + '  ' + self.format_string(parseFloat(transaction.attributes.balancedue).toFixed(2), 7, 'right') + '\n'
                });
            }
            request += builder.createTextElement({ data: '\n' });
            request += builder.createTextElement({ data: '\n' });
        }
        else if (transaction.attributes.balancedue < 0) {
            if (store.getters['printer/paperwidth'] == '58mm') {
                request += builder.createTextElement({
                    data: self.format_string('Wisselgeld', 18) + '  ' + self.format_string(parseFloat(transaction.attributes.balancedue).toFixed(2), 7, 'right') + '\n'
                });
            } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
                request += builder.createTextElement({
                    data: self.format_string('Wisselgeld', 30) + '  ' + self.format_string(parseFloat(transaction.attributes.balancedue).toFixed(2), 9, 'right') + '\n'
                });
            }
        }

        request += builder.createTextElement({ data: '\n' });

        // Paymenttypes
        if (store.getters['printer/paperwidth'] == '58mm') {
            transaction.relationships.payments.forEach(function(payment) {
                request += builder.createTextElement({
                    data: self.format_string(payment.attributes.paymenttype, 18) + '  ' + self.format_string(parseFloat(payment.attributes.amount).toFixed(2), 7, 'right') + '\n'
                });
            });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            transaction.relationships.payments.forEach(function(payment) {
                request += builder.createTextElement({
                    data: self.format_string(payment.attributes.paymenttype, 30) + '  ' + self.format_string(parseFloat(payment.attributes.amount).toFixed(2), 9, 'right') + '\n'
                });
            });
        }
        request += builder.createTextElement({ data: '\n' });

        // Taxrates
        if (store.getters['printer/paperwidth'] == '58mm') {
            for (let index in transaction.relationships.taxes) {
                request += builder.createTextElement({
                    data: self.format_string('', 8) + '  ' + self.format_string('BTW ' + index, 8) + '  ' + self.format_string(parseFloat(transaction.relationships.taxes[index]).toFixed(2), 7, 'right') + '\n'
                });
            }
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            for (let index in transaction.relationships.taxes) {
                request += builder.createTextElement({
                    data: self.format_string('', 22) + '  ' + self.format_string('BTW ' + index, 8) + '  ' + self.format_string(parseFloat(transaction.relationships.taxes[index]).toFixed(2), 7, 'right') + '\n'
                });
            }
        }
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });


        //  subtotal
        if (transaction.relationships.subtotal) {
            request += builder.createRuledLineElement({ thickness: 'double_thin' });
            request += builder.createAlignmentElement({ position: 'center' });
            request += builder.createTextElement({
                emphasis: true,
                data: transaction.relationships.subtotal.attributes.name + '\n'
            });
            request += builder.createRuledLineElement({ thickness: 'double_thin' });
            request += builder.createTextElement({
                data: '\n',
                emphasis: false
            });
        }

        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ data: transaction.attributes[store.getters['terminal/terminal'].receipt_reference] + '\n' });


        // timestamp
        request += builder.createTextElement({
            emphasis: false
        });
        request += builder.createTextElement({ data: transaction.attributes.time + ' ' + transaction.attributes.date + '\n' });

        // eft
        if (store.getters['terminal/terminal'].receipt_type == 'includes_eft') {
            request += builder.createAlignmentElement({ position: 'left' });
            transaction.relationships.payments.forEach(function (payment) {
                if (payment.attributes.eft_receipt_customer) {
                    request += builder.createTextElement({ data: '\n' });
                    request += builder.createRuledLineElement({ thickness: 'thin' });
                    JSON.parse(payment.attributes.eft_receipt_customer).forEach(function (line) {
                        request += builder.createTextElement({ data: line + '\n' });
                    })
                    request += builder.createTextElement({ data: '\n' });
                }
            })
        }

        request += builder.createTextElement({ data: '\n' });
        request += builder.createLogoElement({number:2, width:'single', height:'single'});


        request += builder.createCutPaperElement({
            feed: true,
            type: 'full',//store.printer.autocutter,
        });

        // add to queue
        addPrintjob(request);

        return;
    }


    format_string(value, length, align = 'left') {
        let str = value.toString();

        while (str.length < length) {
            if (align == 'right') {
                str = ' ' + str;
            } else {
                str = str + ' ';
            }
        }
        if (str.length <= length) {
            return str
        }
        return str.slice(0, length)
        // return string;

    }
}
