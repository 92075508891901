import store from '../store';
import { add as addPrintjob } from '../lib/printerqueue';

export default class PrintTable {
    print(table) {
        // eslint-disable-next-line no-undef
        var builder = new StarWebPrintBuilder();

        // Create Request
        var request = '';
        // var paperwidth = 'inch3DotImpact';

        request += builder.createInitializationElement();

        request += builder.createLogoElement({number:1, width:'single', height:'single'});

        request += builder.createTextElement({ characterspace: 2 });
        request += builder.createAlignmentElement({ position: 'center' });

        request += builder.createTextElement({ emphasis: true });

        let self = this
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: table.relationships.location.attributes.name });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: table.relationships.location.attributes.streetname + ' ' + table.relationships.location.attributes.housenumber });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: table.relationships.location.attributes.zipcode + ' ' + table.relationships.location.attributes.city });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: table.relationships.location.attributes.email });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: table.relationships.location.attributes.phone_number });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: table.attributes.name });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ emphasis: false });

        request += builder.createAlignmentElement({ position: 'left' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        // Headings
        if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 11) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 6)
            });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHRIJVING', 17) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('PRIJS', 5) + '   ' + self.format_string('TOTAAL', 6)
            });
        }
        request += builder.createTextElement({
            data: '\n',
            emphasis: false
        });

        // Lines
        if (store.getters['printer/paperwidth'] == '58mm') {
            table.relationships.lines_consolidated.forEach(function (line) {
                if (line.attributes.hidden_receipt) {
                    return
                }
                request += builder.createTextElement({
                    data: self.format_string(line.attributes.description, 11) + '  ' + self.format_string(parseFloat(line.attributes.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'
                })

                // variant
                if (line.attributes.variant) {
                    request += builder.createTextElement({
                        data: ' ' + self.format_string(line.attributes.variant, 20) + '\n'
                    });
                }
                // discount
                if (line.attributes.discount_amount > 0) {
                    request += builder.createTextElement({
                        data: ' Korting ' + parseFloat(line.attributes.discount_amount).toFixed(2) + '\n'
                    });
                }

                // kitchengroceries
                let kitchen_groceries = Object.entries(line.attributes.kitchen_groceries)

                kitchen_groceries.forEach(function (value) {
                    request += builder.createTextElement({
                        data: ' ' + self.format_string(value[0], 7) + ': ' + value[1] + '\n'
                    });
                })
            })

        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            table.relationships.lines_consolidated.forEach(function (line) {
                if (line.attributes.hidden_receipt) {
                    return
                }
                request += builder.createTextElement({
                    data: self.format_string(line.attributes.description, 17) + '  ' + self.format_string(parseFloat(line.attributes.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(line.attributes.rate).toFixed(2), 5, 'right') + '   ' + self.format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'
                });

                // variant
                if (line.attributes.variant) {
                    request += builder.createTextElement({
                        data: ' ' + self.format_string(line.attributes.variant, 25) + '\n'
                    });
                }

                // discount
                if (line.attributes.discount_amount > 0) {
                    request += builder.createTextElement({
                        data: ' Korting ' + parseFloat(line.attributes.discount_amount).toFixed(2) + '\n'
                    });
                }

                // kitchengroceries
                let kitchen_groceries = Object.entries(line.attributes.kitchen_groceries)

                kitchen_groceries.forEach(function (value) {
                    request += builder.createTextElement({
                        data: ' ' + self.format_string(value[0], 7) + ': ' + value[1] + '\n'
                    });
                })
            })
        }

        request += builder.createRuledLineElement({ thickness: 'double_thin' });

        if (table.relationships.lines_consolidated.length > 0) {
            if (store.getters['printer/paperwidth'] == '58mm') {
                request += builder.createTextElement({
                    emphasis: true,
                    data: self.format_string('TOTAAL', 16) + '  ' + self.format_string(parseFloat(table.attributes.total).toFixed(2), 8, 'right') + '\n'
                });
            } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
                request += builder.createTextElement({
                    emphasis: true,
                    data: self.format_string('TOTAAL', 32) + '  ' + parseFloat(table.attributes.total).toFixed(2) + '\n'
                });
            }
            request += builder.createTextElement({
                data: '\n',
                emphasis: false
            });
        }

        request += builder.createTextElement({ data: '\n' });


        // Taxrates
        if (store.getters['printer/paperwidth'] == '58mm') {
            for (let index in table.relationships.taxes) {
                request += builder.createTextElement({
                    data: self.format_string('BTW ' + index, 18, 'right') + '  ' + self.format_string(parseFloat(table.relationships.taxes[index]).toFixed(2), 6, 'right') + '\n'
                });
            }
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            for (let index in table.relationships.taxes) {
                request += builder.createTextElement({
                    data: self.format_string('', 24) + '  ' + self.format_string('BTW ' + index, 4) + '  ' + self.format_string(parseFloat(table.relationships.taxes[index]).toFixed(2), 6) + '\n'
                });
            }
        }
        request += builder.createTextElement({ data: '\n' });

        // timestamp
        request += builder.createTextElement({ emphasis: false });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ data: table.timestamp_formatted + '\n' });
        // request += builder.createTextElement({ data: transaction.attributes.time + ' ' + transaction.attributes.date + '\n' });

        // request += builder.createTextElement({
        //     emphasis: false
        // });

        request += builder.createCutPaperElement({
            feed: true,
            type: 'full',//store.printer.autocutter,
        });

        // add to queue
        addPrintjob(request);

        return;
    }


    format_string(value, length, align = 'left') {
        let str = value.toString();

        while (str.length < length) {
            if (align == 'right') {
                str = ' ' + str;
            } else {
                str = str + ' ';
            }
        }
        if (str.length <= length) {
            return str
        }
        return str.slice(0, length)
        // return string;

    }
}
