<template>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">

                  <modal-close @close="close()"/>

                    <!-- select subtotal -->
                    <div>
                        <div class="flex justify-between items-center mb-5">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    {{$t('translations.components.modal-subtotal.title')}}
                                </h3>
                            </div>
                        </div>
                        <ul class="flex space-x-4">
                            <li @click="select(subtotal)" v-for="(subtotal, index) in subtotals" :key="index" class="flex-grow">
                                <div class="button button-ghost button-large">
                                    <div class="text-center">
                                        <h3 class="uppercase text-lg leading-5 font-medium truncate">{{ subtotal.attributes.name }}</h3>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import ModalClose from './ModalClose.vue';

// todo: make global available
const cache = setupCache({
    maxAge: 15 * 60 * 1000
})
const axiosCache = axios.create({
    adapter: cache.adapter
})

export default {
    // emit: ['selected'],
    components : {
      ModalClose,
    },
    data () {
        return {
            active: false,
            subtotals: {},
        }
    },

    methods: {
        async open() {
            await this.getSubtotals();
            this.active = true
        },

        close() {
            this.active = false
        },

        select(subtotal) {
            this.$store.dispatch('transaction/setSubtotal', subtotal);

            this.$emit('selected')
        },

        async getSubtotals() {
            axiosCache({
                url: '/subtotals',
                method: 'get'
            })
            .then(response => {
                this.subtotals = response.data.data
            }).catch(error => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                console.error(error)
            })
        },
    },
}
</script>
